import React from "react";
import Selfie from "./Images/selfie1.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from "./navbar";
import './styling.css'
import { useEffect } from "react";
import { SocialIcon } from 'react-social-icons';

function About() {
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 10;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      useEffect(()=>{
        reveal()
      })
      window.addEventListener("scroll", reveal);
    return (
      <>
      <NavigationBar />
        <div class='row' style={{size:'100%', paddingLeft:100, paddingTop:50, marginRight:0, }}>
            <h3 className='font-link section reveal'style={{fontSize:100, color:'#023020'}}>About Me</h3>
        </div>
        <div class='row' style={{width:'100%', paddingLeft:100, paddingRight:100, paddingBottom:100, paddingTop:10}}>
        <div class='col-sm-1'></div>
            <div class='col-sm-3'>
                <img src={Selfie} width={400} height={'auto'} className='section reveal'></img>
            </div>
            <div class='col-sm-2'></div>
            <div class='col-sm-5 my-auto'>
                <p className='section reveal'>
                Julie Farley is a a mom of four, living on an island outside of Seattle. She has a bachelor’s degree from the University of Notre Dame and a master’s degree 
                in Education from DePaul University. She has written a three book romance series that was published by a now defunct small press and is currently self-published. 
                Her books have been translated and published in Italy and Denmark.
                </p>
            </div>
            <div class='col-sm-1'></div>
        </div>
        <div id='copyright' className="home2" style={{position:'sticky', bottom:0, zIndex:1000, backgroundColor:'white',paddingTop:30, paddingBottom:30}}>
        <div class='row' style={{ textAlign:'center', marginRight:0}}>
          <div class='col-sm-9' style={{}}>
            <p>Copyright &copy; 2023 Julie Farley. All Rights Reserved</p>
          </div>
          <div class='col-sm-3' style={{textAlign:'center'}}>
          <p>Follow me on: <span><SocialIcon url="https://www.instagram.com/julfarley/?hl=en" color="red" style={{marginRight:0, height:30, width:30, alignItems:'right', color:'grey'}} /></span></p>
          </div>
          
            
        </div>
    </div>
      
      </>
    );
  }




  
export default About;