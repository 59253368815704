import React from "react";
import { NavLink } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "./Images/JFlogo.png"
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// Navigation bar if you are logged into the platform. 
// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

export default function NavigationBar() {

  return (
    <>
    <div className="navigation" style={{position:'sticky', top:0, zIndex:1000}} >
      <nav className="navbar navbar-expand navbar-light bg-light">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
          <img src={logo} width={30} height={'auto'} style={{margin:10}}></img>Julie Farley 
          </NavLink>
          <div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to={'/books'}>
                  Books
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={'/about'}>
                  About Me
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>


    </>
  );
}
