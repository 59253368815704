import React, { useEffect } from "react";
import Cover1 from "./Images/Cover1.jpg"
import Cover2 from "./Images/Cover3v2.png"
import Cover3 from "./Images/Cover3.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from "./navbar";
import Button from 'react-bootstrap/Button';
import { SocialIcon } from 'react-social-icons';
import './styling.css'

function Books() {
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 10;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      useEffect(()=>{
        reveal()
      })
      window.addEventListener("scroll", reveal);

    return (
      <>
      <NavigationBar />
        {/* <div class='row' style={{paddingTop:150, paddingBottom:150, size:'100%', textAlign:'center', backgroundColor:"#9FE2BF", marginRight:0}}>
            <div class='col-lg-5'>
                <p>Logo Here</p>
            </div>
            <div class='col-lg-7 my-auto'>
                <h1 className='font-link section reveal'style={{fontSize:100, color:'#023020'}}>Julie Farley</h1>
            </div>
        </div> */}
        <div class='row' style={{size:'100%', paddingLeft:100, paddingTop:50, marginRight:0, }}>
            <h3 className='font-link section reveal'style={{fontSize:100, color:'#023020'}}>Books</h3>
        </div>
        <div class='row' style={{size:'100%', paddingLeft:100, paddingRight:100, paddingBottom:100, paddingTop:10, marginRight:0}}>
            
            <div class='col-sm-1'></div>
            <div class='col-sm-4' style={{textAlign:'center'}}>
                <img src={Cover3} className='section reveal' width={300} height={'auto'}></img>
            </div>
            <div class='col-sm-5 my-auto'>
                <h1 style={{color:"#00008B"}} className='section reveal'>Tripped Up Love</h1>
                <p className='section reveal'>
                    When Heather Meadows loses the only man she's ever loved, her perfect, ordinary life is turned upside down. The happily 
                    ever after her late husband promised disappears in the blink of an eye. As she picks up the pieces of her shattered world
                    and puts on a brace face for her three children, her life becomes the subject of scrutiny and pity from her well-intentioned 
                    friends and neighbors who do more harm than good. Life in suburbia is not for the faint of heart. 

                    Little does she know that her world is about to be turned upside down again when one wrong step puts her in the path of a new destiny. 
                    Heather finds a reason to smile again, but she wonders how she'll handle the prying eyes and unspoken judgements of those closest to her. 

                    Tripped Up Love is a story of family, home, new beginnings, and moving forward one step at a time.
                </p>
                <a href='https://www.amazon.com/gp/product/B01GGDL5DA?notRedirectToSDP=1&ref_=dbs_mng_calw_0&storeType=ebooks' className='section reveal'><Button variant='primary'>Purchase Here</Button></a>
            </div>
            <div class='col-sm-2'></div>
        </div>
        
        <div class='row' style={{size:'100%',  padding:100, marginRight:0}}>
            <div class='col-sm-1'></div>
            <div class='col-sm-4' style={{textAlign:'center'}}>
                <img src={Cover1} className='section reveal' width={300} height={'auto'}></img>
            </div>
            <div class='col-sm-5 my-auto'>
                <h1 style={{color:"#00008B"}} className='section reveal'>The New Ever After</h1>
                <p className='section reveal'>
                    Heather Meadows has been given a second chance at happiness. When her first husband passed away, she never thought 
                    she’d love again but a fortuitous meeting with a charming author changed the course of her life — in more ways than one.
                    Thanks to the love and support of her sexy new partner, the mother of three became a mother of five and embarked on a 
                    promising career as a writer. But despite her newfound joy, her fast-paced life begins to take its toll. The New Ever 
                    After is a tale of friendship, sacrifice, do overs, and making it work.
                </p>
                <a href='https://www.amazon.com/gp/product/B01GEJ5H4Y?notRedirectToSDP=1&ref_=dbs_mng_calw_2&storeType=ebooks' className='section reveal'><Button variant='primary'>Purchase Here</Button></a>
            </div>
            <div class='col-sm-2'></div>
            
        </div>
        
        <div class='row' style={{size:'100%', padding:100, marginRight:0}}>
            
            <div class='col-sm-1'></div>
            <div class='col-sm-4' style={{textAlign:'center'}}>
                <img src={Cover2} className='section reveal' width={300} height={'auto'}></img>
            </div>
            <div class='col-sm-5 my-auto'>
                <h1 style={{color:"#00008B"}} className='section reveal'>Another Tomorrow</h1>
                <p className='section reveal'>
                    Heather Meadows has finally gotten her life in order and is enjoying some much-deserved happiness after being widowed with three children. 
                    But now that Heather is happily remarried and her children are thriving once more, her best friend Jenny’s life is falling apart. 
                    Despite Heather’s support, Jenny’s marriage is doomed and nothing the two friends plan is enough to renew Jenny’s faith in getting 
                    her own opportunityfor happiness. But when a chance meeting with an old friend from high school alters the course of Jenny’s life she 
                    wonders if maybe there’s hope after all. Another Tomorrow is a story of friendship, hope, and taking life as it comes.
                </p>
                <a href='https://www.amazon.com/gp/product/B01GFCIHK6?notRedirectToSDP=1&ref_=dbs_mng_calw_1&storeType=ebooks' className='section reveal'><Button variant='primary'>Purchase Here</Button></a>
            </div>
            <div class='col-sm-2'></div>
        </div>

        <div id='copyright' className="home2" style={{position:'sticky', bottom:0, zIndex:1000, backgroundColor:'white',paddingTop:30, paddingBottom:30}}>
        <div class='row' style={{ textAlign:'center', marginRight:0}}>
          <div class='col-sm-9' style={{}}>
            <p>Copyright &copy; 2023 Julie Farley. All Rights Reserved</p>
          </div>
          <div class='col-sm-3' style={{textAlign:'center'}}>
          <p>Follow me on: <span><SocialIcon url="https://www.instagram.com/julfarley/?hl=en" color="red" style={{marginRight:0, height:30, width:30, alignItems:'right', color:'grey'}} /></span></p>
          </div>
          
            
        </div>
    </div>
      
      </>
    );
  }




  
export default Books;