import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Books, About, Home } from "./Components"

import React from "react";

function App() {
  return (
   <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/books' element={<Books />} />
      <Route path='/about' element={<About />} />
   </Routes>
  );
}

export default App;
