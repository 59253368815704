import React, { useEffect } from "react";
import logo from "./Images/JFlogo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from "./navbar";
import Button from 'react-bootstrap/Button';
import { SocialIcon } from 'react-social-icons';
import './styling.css'
import { NavLink } from "react-router-dom";
function Home() {
    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 10;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      useEffect(()=>{
        reveal()
      })
      window.addEventListener("scroll", reveal);

    return (
      <>
        <body style={{height:'100%', position:'relative'}}>
        <div class='row' style={{paddingTop:100, paddingBottom:20, size:'100%', textAlign:'center', marginRight:0, }}>
            <div class='col-lg-12'>
            <img src={logo} className='section reveal' width={200} height={'auto'}></img>
            </div>
        </div>
        <div class='row' style={{paddingTop:20, paddingBottom:20, size:'100%', textAlign:'center', marginRight:0}}>
        <h1 className='font-link section reveal'style={{fontSize:100, color:'#023020'}}>Julie Farley</h1>
        </div>
        <div class='row section reveal' style={{paddingTop:0, paddingBottom:20, size:'100%', textAlign:'center', marginRight:0}}>
            <div class='col-sm-6' style={{textAlign:'right'}}>
                <NavLink style={{color:'#585858', textDecoration:'none', fontSize:28}} to="/books">
                    <p>Books</p>
                </NavLink>
            </div>
            <div class='col-sm-6'  style={{textAlign:'left'}}>
                <NavLink style={{color:'#585858', textDecoration:'none', fontSize:28}} to="/about">
                    About
                </NavLink>
            </div>
        </div>
        <div id='copyright' className="home2" style={{position:'sticky', bottom:0, zIndex:1000, backgroundColor:'white',paddingTop:30, paddingBottom:30}}>
        <div class='row' style={{ textAlign:'center', marginRight:0}}>
          <div class='col-sm-9' style={{}}>
            <p>Copyright &copy; 2023 Julie Farley. All Rights Reserved</p>
          </div>
          <div class='col-sm-3' style={{textAlign:'center'}}>
          <p>Follow me on: <span><SocialIcon url="https://www.instagram.com/julfarley/?hl=en" color="red" style={{marginRight:0, height:30, width:30, alignItems:'right', color:'grey'}} /></span></p>
          </div>
          
            
        </div>
    </div>
    </body>
      </>
    );
  }




  
export default Home;